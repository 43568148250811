export default {
  amountDecimalPlace: 2,
  priceDecimalPlace: 1,
  quantityDecimalPlace: 1,
  pencentageDecimalPlace: 1,
  rateDecimalPlace: 2,
  decimalSymbol: ',',
  thousandsSeparator: '.',
  dateFormat: 'DD.MM.YYYY',
  timeFormat: 'TWENTYFOUR_HOUR',
  language: 'en-CA',
  companyId: 411,
  companyName: 'colgate-dev1122w',
  companyLogoId: null,
  locationCountry: 'United States 2',
  locationState: '2California1',
  locationCity: '2LA',
  locationStreetAddress: '1288888',
  locationPostalCode: '12345',
  timeZone: 'GMT1000',
  email: 'sz.cn@sap.com',
  phone: '221',
  website: 'http://sap.com.shshhshshs.123444442',
  creationTime: '2020-10-12T13:53:02.350015',
  id: 418,
  userName: { firstName: 'sz-test2-120731', lastName: 'shengzhene' },
  userUpdateState: 299,
  status: 'INITIAL',
  tenantId: 5279,
  avatarIcon: { type: 'Initials', value: 'SS' },
};

export const components = [
  {
    name: 'comment-ui',
    location: 'https://cdn.eurekasap.io/eureka/comment-ui/9cbb50a',
    config: {
      app: 'comment',
      fragments: [
        {
          name: 'Comments',
          section: ['comments'],
        },
      ],
      routers: [],
    },
  },
  {
    name: 'user-ui',
    location: 'https://cdn.eurekasap.io/cic/user-ui/7084d96',
    config: {
      app: 'user',
      fragments: [
        {
          name: 'Profile',
          section: ['shell-user_profile'],
        },
        {
          name: 'Account',
          section: ['user-profile_account'],
        },
        {
          name: 'LanguageAndRegion',
          section: ['user-profile_language_region'],
        },
        {
          name: 'ChangePassword',
          section: ['user-profile_change_password'],
        },
        {
          name: 'UsersMgmt',
          section: ['configuration-users_mgmt', 'rm-role-permission-users'],
        },
        {
          name: 'RoleAndPermission',
          section: ['configuration-role_permission', 'rm-role-permission-roles'],
        },
        {
          name: 'Responsibilities',
          section: ['configuration-responsibilities'],
        },
      ],
      routers: ['/user/profile'],
    },
  },
  {
    name: 'configuration-ui',
    location: 'https://cdn.eurekasap.io/cic/configuration-ui/fd69ed5',
    config: {
      app: 'configuration',
      entrypoints: [
        {
          title: 'Configuration_CompanySettingsTitle',
          text: 'Configuration_ViewCompanySettings',
          icon: 'sap-icon--product',
          router: '/configuration/setup',
        },
      ],
      routers: [
        '/configuration/profile/:id',
        '/configuration/setup',
        '/configuration/users-mgmt',
        '/configuration/integration',
        '/configuration/business-rules',
        '/configuration/settlement',
        '/configuration/communication',
        '/configuration/api-mgmt',
      ],
      sidenav: [
        {
          id: 'settings',
          text: 'Settings',
          icon: 'settings',
          router: '',
          items: [
            {
              id: 'setup',
              text: 'SideNav_Settings_SetUp',
              router: '/configuration/setup',
              order: 71,
              permissions: ['BasicSetup.READ', 'CompanyProfile.READ'],
            },
            {
              id: 'integration',
              text: 'SideNav_Settings_Integration',
              router: '/configuration/integration',
              order: 72,
              permissions: ['ReasonCodeConfiguration.READ'],
            },
            {
              id: 'user-roles',
              text: 'SideNav_Settings_UsersAndRoles',
              router: '/configuration/users-mgmt',
              order: 73,
              permissions: ['UsersandRolePermission.READ', 'Responsibility.READ'],
              conjunction: 'OR',
            },
            {
              id: 'settlement',
              text: 'SideNav_Settings_Settlement',
              router: '/configuration/settlement',
              order: 75,
              permissions: ['RunConfig.READ'],
            },
            {
              id: 'api-mgmt',
              text: 'SideNav_Settings_ApiMgmt',
              router: '/configuration/api-mgmt',
              order: 76,
              permissions: ['UsersandRolePermission.READ'],
            },
          ],
          order: 70,
        },
      ],
    },
  },
  {
    name: 'excelextraction-ui',
    location: 'https://cdn.eurekasap.io/eureka/excelextraction-ui/f03122f',
    config: {
      app: 'excel-extraction',
      routers: ['/excel-extraction/:id'],
    },
  },
  {
    name: 'login-ui',
    location: 'https://cdn.eurekasap.io/eureka/login-ui/a1ee218',
    config: {
      app: 'login',
      routers: ['/login', '/access-denied', '/error/404'],
    },
  },
  {
    name: 'demo-data-ui',
    location: 'https://cdn.eurekasap.io/eureka/demo-data-ui/1a22a67',
    config: {
      app: 'demo-data',
      entrypoints: [
        {
          title: 'Demo Data',
          text: 'Demo Data Management Beta 0.2',
          icon: 'sap-icon--approvals',
          router: '/demo-data/',
        },
      ],
      routers: ['/demo-data'],
    },
  },
  {
    name: 'document-library-ui',
    location: 'https://cdn.eurekasap.io/eureka/document-library-ui/4101b6f',
    config: {
      app: 'document-library',
      sidenav: [
        {
          id: 'document-library',
          text: 'Documents',
          icon: 'documents',
          router: '/document-library',
          permissions: ['document.READ'],
          order: 35,
        },
      ],
      entrypoints: [
        {
          title: 'Documents',
          text: 'View and Upload Documents',
          icon: 'sap-icon--documents',
          router: '/document-library',
        },
      ],
      fragments: [
        {
          name: 'DocumentViewer',
          section: ['document-viewer'],
        },
      ],
      routers: ['/document-library', '/document-library/details/:id'],
    },
  },
  {
    name: 'analytics-dashboard-ui',
    location: 'https://cdn.eurekasap.io/eureka/analytics-dashboard-ui/a418fe5',
    config: {
      app: 'analytics-dashboard',
      sidenav: [
        {
          id: 'analytics-dashboard',
          text: 'SideNav_Analytics_Dashboard',
          icon: 'home',
          router: '/analytics-dashboard',
          permissions: ['Claim.READ'],
          order: 10,
        },
      ],
      entrypoints: [
        {
          title: 'Dashboard',
          text: 'Tile_Analytics_Dashboard',
          icon: 'sap-icon--home',
          router: '/analytics-dashboard',
        },
      ],
      routers: ['/analytics-dashboard'],
    },
  },
  {
    name: 'aropenitem-ui',
    location: 'https://cdn.eurekasap.io/cic/aropenitem-ui/7de1b7a',
    config: {
      app: 'aropenitem',
      entrypoints: [
        {
          title: 'A/R Residual Open Items',
          text: 'A/R Residual Open Items',
          icon: 'sap-icon--approvals',
          router: '/aropenitem/non-classified',
        },
      ],
      fragments: [
        {
          name: 'Setting',
          section: ['integration-aropenitem'],
        },
      ],
      routers: [
        '/aropenitem/non-classified',
        '/aropenitem/non-classified/:id/view',
        '/aropenitem/non-classified/:id/edit',
        '/aropenitem/non-trade',
        '/aropenitem/non-trade/:id/view',
      ],
      sidenav: [
        {
          id: 'aropenitems',
          text: 'Aropenitem_MenuTitle',
          icon: 'accounting-document-verification',
          disabled: false,
          router: '',
          order: 60,
          items: [
            {
              id: 'aropenitems-non-classified',
              text: 'Aropenitem_NonClassified',
              router: '/aropenitem/non-classified',
              order: 61,
              permissions: ['ARopenitem.READ'],
            },
            {
              id: 'aropenitems-non-trade',
              text: 'Aropenitem_NonTrade',
              router: '/aropenitem/non-trade',
              order: 62,
              permissions: ['ARopenitem.READ'],
            },
          ],
        },
      ],
    },
  },
  {
    name: 'company-ui',
    location: 'https://cdn.eurekasap.io/cic/company-ui/0442c6a',
    config: {
      app: 'company',
      fragments: [
        {
          name: 'Profile',
          section: ['setup-company_profile'],
        },
        {
          name: 'BasicSetup',
          section: ['setup-company_basic_setup'],
        },
      ],
      routers: ['/company/profile'],
    },
  },
  {
    name: 'fragments-ui',
    location: 'https://cdn.eurekasap.io/eureka/fragments-ui/c624c12',
    config: {
      app: 'fragments',
      entrypoints: [
        {
          title: 'Application Sample',
          text: 'Sample page',
          icon: 'sap-icon--approvals',
          router: '/glory/sample/page',
        },
        {
          title: 'URL Sample',
          text: 'Google search',
          icon: 'sap-icon--search',
          url: 'https://www.google.com',
        },
      ],
      fragments: [
        {
          name: 'fragmentsReactFragmentSample1',
          section: ['Integration', 'Claims'],
        },
        {
          name: 'fragmentsReactFragmentSample2',
          section: ['Integration'],
        },
        {
          name: 'DocumentSearchModalContainer',
          section: ['document-search-modal-container'],
        },
        {
          name: 'DocumentSearchFilterBar',
          section: ['document-search-filter-bar'],
        },
        {
          name: 'PromotionSearchFilterBar',
          section: ['promotion-search-filterbar'],
        },
        {
          name: 'DocumentUploadContainer',
          section: ['document-upload-container'],
        },
      ],
      routers: [
        '/businesspartner/list',
        '/businesspartner/edit/:id',
        '/businesspartner/view/:id',
        '/fragments/product/list',
        '/fragments/product/view/:id',
        '/fragments/product/edit/:id',
        '/fragments/product/create',
        'fragments/test/linkpromotionmodal',
      ],
    },
  },
  {
    name: 'sdmtest2-ui',
    location: 'https://cdn.eurekasap.io/eureka/sdmtest2-ui/363fd53',
    config: {
      app: 'sdmtest2',
      entrypoints: [
        {
          title: 'Application Sample',
          text: 'Sample page',
          icon: 'sap-icon--approvals',
          router: '/glory/sample/page',
        },
        {
          title: 'URL Sample',
          text: 'Google search',
          icon: 'sap-icon--search',
          url: 'https://www.google.com',
        },
      ],
      fragments: [
        {
          name: 'sdmtest2ReactFragmentSample1',
          section: ['Integration', 'Claims'],
        },
        {
          name: 'sdmtest2ReactFragmentSample2',
          section: ['Integration'],
        },
      ],
      routers: [
        '/businesspartner/list',
        '/businesspartner/edit/:id',
        '/businesspartner/view/:id',
        '/sdmtest2/product/list',
        '/sdmtest2/product/view/:id',
        '/sdmtest2/product/edit/:id',
        '/sdmtest2/product/create',
      ],
    },
  },
  {
    name: 'settlement-ui',
    location: 'https://cdn.eurekasap.io/cic/settlement-ui/2ef3e61',
    config: {
      app: 'settlement',
      entrypoints: [
        {
          title: 'Settlement',
          text: 'Settlement page',
          icon: 'sap-icon--approvals',
          router: '/settlement/list',
        },
      ],
      fragments: [
        {
          name: 'Run',
          section: ['settlement-run'],
        },
      ],
      routers: ['/settlement/runs', '/settlement/due-list', '/settlement/list', '/settlement/:id'],
      sidenav: [
        {
          id: 'settlement-menu',
          text: 'Settlement_MenuTitle',
          icon: 'accounting-document-verification',
          disabled: false,
          router: '',
          order: 50,
          items: [
            {
              id: 'settlement-due-list',
              text: 'Settlement_DueList',
              router: '/settlement/due-list',
              permissions: ['DueList.READ'],
              order: 51,
            },
            {
              id: 'settlement-runs',
              text: 'Settlement_Runs',
              router: '/settlement/runs',
              permissions: ['SettlementRun.READ'],
              order: 52,
            },
            {
              id: 'settlement-documents',
              text: 'Settlement_Documents',
              router: '/settlement/list',
              permissions: ['ClaimsSettlement.READ'],
              order: 53,
            },
          ],
        },
      ],
    },
  },
  {
    name: 'api-management-ui',
    location: 'https://cdn.eurekasap.io/cic/api-management-ui/abb070a',
    config: {
      app: 'api-management',
      fragments: [
        {
          name: 'ClientSecret',
          section: ['api-management_client-secret'],
        },
      ],
      routers: [],
    },
  },
  {
    name: 'approval-ui',
    location: 'https://cdn.eurekasap.io/eureka/approval-ui/37d1b40',
    config: {
      app: 'approval',
      sidenav: [
        {
          id: 'approval',
          text: 'SideNav_Approval',
          icon: 'customer-financial-fact-sheet',
          router: '/approvals',
          permissions: ['approval.READ'],
          order: 20,
        },
      ],
      entrypoints: [
        {
          title: 'Approvals',
          text: 'Tile_Approvals',
          icon: 'sap-icon--activity-items',
          router: '/approvals',
        },
      ],
      routers: ['/approvals'],
    },
  },
  {
    name: 'claims-submission-ui',
    location: 'https://cdn.eurekasap.io/eureka/claims-submission-ui/bd8242f',
    config: {
      app: 'claims',
      sidenav: [
        {
          id: 'claims1',
          text: 'Claims',
          icon: 'customer-financial-fact-sheet',
          router: '/claims/',
          permissions: ['Claim.READ'],
          order: 21,
        },
      ],
      entrypoints: [
        {
          title: 'Claims',
          text: 'ClaimsManagement',
          icon: 'sap-icon--activity-items',
          router: '/claims/',
        },
      ],
      routers: ['/claims/:filter?', '/claims/details/:id'],
    },
  },
];

export const setting1 = {
  data: {
    id: 304,
    amountDecimalPlace: 2,
    priceDecimalPlace: 1,
    quantityDecimalPlace: 1,
    pencentageDecimalPlace: 1,
    rateDecimalPlace: 2,
    decimalSymbol: ',',
    thousandsSeparator: '.',
    dateFormat: 'DD.MM.YYYY',
    timeFormat: 'TWENTYFOUR_HOUR',
    language: 'zh-CN',
    companyId: 411,
  },
};
export const setting2 = {
  data: {
    id: 418,
    userName: { firstName: 'sz-test2-120731', lastName: 'shengzhene' },
    pictureId: null,
    email: 'sz.cn@sap.com',
    phone: '221',
    language: 'en-US',
    dateFormat: 'DD.MM.YYYY',
    timeFormat: 'TWENTYFOUR_HOUR',
    timeZone: 'GMT1000',
    creationTime: '2020-10-12T13:53:02.350015',
    userUpdateState: 297,
    status: 'INITIAL',
    tenantId: 5279,
    role: null,
    externalUserId: null,
    avatarIcon: { type: 'Initials', value: 'SS' },
    familyNameFirst: false,
    s4ExternalUserId: null,
  },
};
export const setting3 = {
  data: {
    id: 410,
    companyName: 'colgate-dev1122w',
    companyLogoId: null,
    locationCountry: 'United States 2',
    locationState: '2California1',
    locationCity: '2LA',
    locationStreetAddress: '1288888',
    locationPostalCode: '12345',
    timeZone: 'GMT_0500',
    email: 'eureka2@sap.com',
    phone: '123456',
    website: 'http://sap.com.shshhshshs.123444442',
    creationTime: '2020-10-12T06:53:44.167322',
  },
};
export const setting4 = {
  data: {
    id: 78,
    userName: 'shengzhene',
    emails: [{ value: 'sz.cn@sap.com', primary: true }],
    tenantId: 5279,
    wholeName: 'shengzhene',
    powerUser: false,
    uuaId: '31c3f3f3-4b57-44de-97a9-9ef101010972',
    roleWithPermission: [
      {
        id: 99999,
        name: 'System Admin',
        description: 'Power User Role',
        permissionItem: [
          {
            authObject: 'BUSINESS_OBJECTS',
            score: 90,
            hide: false,
            items: [
              {
                authItemDisplayName: 'Due List',
                authItemValue: 'DueList',
                accesses: [
                  {
                    accessId: 13,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [13],
                    isDefault: false,
                  },
                  {
                    accessId: 16,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [16, 13],
                    isDefault: false,
                  },
                  {
                    accessId: 14,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [16, 13, 14],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'Line Item',
                authItemValue: 'LineItem',
                accesses: [
                  {
                    accessId: 110,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [110],
                    isDefault: false,
                  },
                  {
                    accessId: 107,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [107],
                    isDefault: false,
                  },
                  {
                    accessId: 106,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [106, 107],
                    isDefault: false,
                  },
                  {
                    accessId: 108,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [106, 107, 108, 110],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'Settlement Run',
                authItemValue: 'SettlementRun',
                accesses: [
                  {
                    accessId: 9,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [9],
                    isDefault: false,
                  },
                  {
                    accessId: 12,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [9, 12],
                    isDefault: false,
                  },
                  {
                    accessId: 10,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [9, 10, 12],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'document',
                authItemValue: 'document',
                accesses: [
                  {
                    accessId: 116,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [116],
                    isDefault: false,
                  },
                  {
                    accessId: 112,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [112],
                    isDefault: false,
                  },
                  {
                    accessId: 114,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [112, 114],
                    isDefault: false,
                  },
                  {
                    accessId: 111,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [112, 114, 111],
                    isDefault: false,
                  },
                  {
                    accessId: 113,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [112, 113, 114, 116, 111],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'Debit Note',
                authItemValue: 'DebitNote',
                accesses: [
                  {
                    accessId: 105,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [105],
                    isDefault: false,
                  },
                  {
                    accessId: 102,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [102],
                    isDefault: false,
                  },
                  {
                    accessId: 101,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [101, 102],
                    isDefault: false,
                  },
                  {
                    accessId: 103,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [101, 102, 103, 105],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'Approval',
                authItemValue: 'Approval',
                accesses: [
                  {
                    accessId: 94,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [94],
                    isDefault: false,
                  },
                  {
                    accessId: 90,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [90],
                    isDefault: false,
                  },
                  {
                    accessId: 91,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [90, 91],
                    isDefault: false,
                  },
                  {
                    accessId: 89,
                    accessName: 'APPROVE',
                    accessDisplayName: 'Approve',
                    subAccessId: [89, 90, 91],
                    isDefault: false,
                  },
                  {
                    accessId: 88,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [88, 90, 91],
                    isDefault: false,
                  },
                  {
                    accessId: 92,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [88, 89, 90, 91, 92, 94],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'Claims Settlement',
                authItemValue: 'ClaimsSettlement',
                accesses: [
                  {
                    accessId: 5,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [5],
                    isDefault: false,
                  },
                  {
                    accessId: 8,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [5, 8],
                    isDefault: false,
                  },
                  {
                    accessId: 6,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [5, 6, 8],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'Claim',
                authItemValue: 'Claim',
                accesses: [
                  {
                    accessId: 136,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [136],
                    isDefault: false,
                  },
                  {
                    accessId: 135,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [135],
                    isDefault: false,
                  },
                  {
                    accessId: 140,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [135, 140],
                    isDefault: false,
                  },
                  {
                    accessId: 138,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [135, 138, 140],
                    isDefault: false,
                  },
                  {
                    accessId: 139,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [135, 136, 138, 139, 140],
                    isDefault: false,
                  },
                ],
              },
            ],
          },
          {
            authObject: 'INTEGRATION_OBJECTS',
            score: 91,
            hide: false,
            items: [
              {
                authItemDisplayName: 'Customer MD',
                authItemValue: 'CustomerMD',
                accesses: [
                  {
                    accessId: 37,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [37],
                    isDefault: false,
                  },
                  {
                    accessId: 40,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [37, 40],
                    isDefault: false,
                  },
                  {
                    accessId: 38,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [37, 38, 40],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'agreement search data',
                authItemValue: 'agreementsearchdata',
                accesses: [
                  {
                    accessId: 87,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [87],
                    isDefault: false,
                  },
                  {
                    accessId: 84,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [84],
                    isDefault: false,
                  },
                  {
                    accessId: 83,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [83, 84],
                    isDefault: false,
                  },
                  {
                    accessId: 82,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [82, 83, 84],
                    isDefault: false,
                  },
                  {
                    accessId: 85,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [82, 83, 84, 85, 87],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'validated amount',
                authItemValue: 'validatedamount',
                accesses: [
                  {
                    accessId: 81,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [81],
                    isDefault: false,
                  },
                  {
                    accessId: 79,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [79],
                    isDefault: false,
                  },
                  {
                    accessId: 78,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [78, 79],
                    isDefault: false,
                  },
                  {
                    accessId: 76,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [76, 78, 79],
                    isDefault: false,
                  },
                  {
                    accessId: 77,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [81, 76, 77, 78, 79],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'AR open item',
                authItemValue: 'ARopenitem',
                accesses: [
                  {
                    accessId: 29,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [29],
                    isDefault: false,
                  },
                  {
                    accessId: 32,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [32, 29],
                    isDefault: false,
                  },
                  {
                    accessId: 30,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [32, 29, 30],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'promotion master data',
                authItemValue: 'promotionmasterdata',
                accesses: [
                  {
                    accessId: 75,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [75],
                    isDefault: false,
                  },
                  {
                    accessId: 73,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [73],
                    isDefault: false,
                  },
                  {
                    accessId: 72,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [72, 73],
                    isDefault: false,
                  },
                  {
                    accessId: 70,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [70, 72, 73],
                    isDefault: false,
                  },
                  {
                    accessId: 71,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [70, 71, 72, 73, 75],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'uom service data',
                authItemValue: 'uomservicedata',
                accesses: [
                  {
                    accessId: 128,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [128],
                    isDefault: false,
                  },
                  {
                    accessId: 125,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [125],
                    isDefault: false,
                  },
                  {
                    accessId: 124,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [124, 125],
                    isDefault: false,
                  },
                  {
                    accessId: 123,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [123, 124, 125],
                    isDefault: false,
                  },
                  {
                    accessId: 126,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [128, 123, 124, 125, 126],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'agreement master data',
                authItemValue: 'agreementmasterdata',
                accesses: [
                  {
                    accessId: 51,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [51],
                    isDefault: false,
                  },
                  {
                    accessId: 49,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [49],
                    isDefault: false,
                  },
                  {
                    accessId: 48,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [48, 49],
                    isDefault: false,
                  },
                  {
                    accessId: 46,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [48, 49, 46],
                    isDefault: false,
                  },
                  {
                    accessId: 47,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [48, 49, 51, 46, 47],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'product master data',
                authItemValue: 'productmasterdata',
                accesses: [
                  {
                    accessId: 69,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [69],
                    isDefault: false,
                  },
                  {
                    accessId: 67,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [67],
                    isDefault: false,
                  },
                  {
                    accessId: 66,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [66, 67],
                    isDefault: false,
                  },
                  {
                    accessId: 64,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [64, 66, 67],
                    isDefault: false,
                  },
                  {
                    accessId: 65,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [64, 65, 66, 67, 69],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'hierarchy master data',
                authItemValue: 'hierarchymasterdata',
                accesses: [
                  {
                    accessId: 63,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [63],
                    isDefault: false,
                  },
                  {
                    accessId: 61,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [61],
                    isDefault: false,
                  },
                  {
                    accessId: 60,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [60, 61],
                    isDefault: false,
                  },
                  {
                    accessId: 58,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [58, 60, 61],
                    isDefault: false,
                  },
                  {
                    accessId: 59,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [58, 59, 60, 61, 63],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'sales area',
                authItemValue: 'salesarea',
                accesses: [
                  {
                    accessId: 122,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [122],
                    isDefault: false,
                  },
                  {
                    accessId: 119,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [119],
                    isDefault: false,
                  },
                  {
                    accessId: 118,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [118, 119],
                    isDefault: false,
                  },
                  {
                    accessId: 117,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [117, 118, 119],
                    isDefault: false,
                  },
                  {
                    accessId: 120,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [117, 118, 119, 120, 122],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'customer master data',
                authItemValue: 'customermasterdata',
                accesses: [
                  {
                    accessId: 57,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [57],
                    isDefault: false,
                  },
                  {
                    accessId: 55,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [55],
                    isDefault: false,
                  },
                  {
                    accessId: 54,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [54, 55],
                    isDefault: false,
                  },
                  {
                    accessId: 52,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [52, 54, 55],
                    isDefault: false,
                  },
                  {
                    accessId: 53,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [52, 53, 54, 55, 57],
                    isDefault: false,
                  },
                ],
              },
            ],
          },
          {
            authObject: 'SETTINGS',
            score: 94,
            hide: false,
            items: [
              {
                authItemDisplayName: 'Company Profile',
                authItemValue: 'CompanyProfile',
                accesses: [
                  {
                    accessId: 21,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [21],
                    isDefault: false,
                  },
                  {
                    accessId: 24,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [21, 24],
                    isDefault: false,
                  },
                  {
                    accessId: 22,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [21, 22, 24],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'Basic Setup',
                authItemValue: 'BasicSetup',
                accesses: [
                  {
                    accessId: 25,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [25],
                    isDefault: false,
                  },
                  {
                    accessId: 28,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [25, 28],
                    isDefault: false,
                  },
                  {
                    accessId: 26,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [25, 26, 28],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'Reason Code Configuration',
                authItemValue: 'ReasonCodeConfiguration',
                accesses: [
                  {
                    accessId: 33,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [33],
                    isDefault: false,
                  },
                  {
                    accessId: 36,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [33, 36],
                    isDefault: false,
                  },
                  {
                    accessId: 34,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [33, 34, 36],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'Users and Role Permission',
                authItemValue: 'UsersandRolePermission',
                accesses: [
                  {
                    accessId: 1,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [1],
                    isDefault: false,
                  },
                  {
                    accessId: 4,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [1, 4],
                    isDefault: false,
                  },
                  {
                    accessId: 2,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [1, 2, 4],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'Responsibility',
                authItemValue: 'Responsibility',
                accesses: [
                  {
                    accessId: 41,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [41],
                    isDefault: false,
                  },
                  {
                    accessId: 45,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [41, 45],
                    isDefault: false,
                  },
                  {
                    accessId: 42,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [41, 42, 45],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'Run Config',
                authItemValue: 'RunConfig',
                accesses: [
                  {
                    accessId: 17,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [17],
                    isDefault: false,
                  },
                  {
                    accessId: 20,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [17, 20],
                    isDefault: false,
                  },
                  {
                    accessId: 18,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [17, 18, 20],
                    isDefault: false,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: 9,
        name: 'Master Role',
        description: 'This is master role',
        permissionItem: [
          {
            authObject: 'BUSINESS_OBJECTS',
            score: 90,
            hide: false,
            items: [
              {
                authItemDisplayName: 'document',
                authItemValue: 'document',
                accesses: [
                  {
                    accessId: 116,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [116],
                    isDefault: false,
                  },
                  {
                    accessId: 112,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [112],
                    isDefault: false,
                  },
                  {
                    accessId: 114,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [112, 114],
                    isDefault: false,
                  },
                  {
                    accessId: 111,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [112, 114, 111],
                    isDefault: false,
                  },
                  {
                    accessId: 113,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [112, 113, 114, 116, 111],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'Debit Note',
                authItemValue: 'DebitNote',
                accesses: [
                  {
                    accessId: 105,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [105],
                    isDefault: false,
                  },
                  {
                    accessId: 102,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [102],
                    isDefault: false,
                  },
                  {
                    accessId: 101,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [101, 102],
                    isDefault: false,
                  },
                  {
                    accessId: 103,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [101, 102, 103, 105],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'Approval',
                authItemValue: 'Approval',
                accesses: [
                  {
                    accessId: 94,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [94],
                    isDefault: false,
                  },
                  {
                    accessId: 90,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [90],
                    isDefault: false,
                  },
                  {
                    accessId: 91,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [90, 91],
                    isDefault: false,
                  },
                  {
                    accessId: 89,
                    accessName: 'APPROVE',
                    accessDisplayName: 'Approve',
                    subAccessId: [89, 90, 91],
                    isDefault: false,
                  },
                  {
                    accessId: 88,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [88, 90, 91],
                    isDefault: false,
                  },
                  {
                    accessId: 92,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [88, 89, 90, 91, 92, 94],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'Claims Settlement',
                authItemValue: 'ClaimsSettlement',
                accesses: [
                  {
                    accessId: 5,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [5],
                    isDefault: false,
                  },
                  {
                    accessId: 8,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [5, 8],
                    isDefault: false,
                  },
                  {
                    accessId: 6,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [5, 6, 8],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'Claim',
                authItemValue: 'Claim',
                accesses: [
                  {
                    accessId: 136,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [136],
                    isDefault: false,
                  },
                  {
                    accessId: 135,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [135],
                    isDefault: false,
                  },
                  {
                    accessId: 140,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [135, 140],
                    isDefault: false,
                  },
                  {
                    accessId: 138,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [135, 138, 140],
                    isDefault: false,
                  },
                  {
                    accessId: 139,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [135, 136, 138, 139, 140],
                    isDefault: false,
                  },
                ],
              },
            ],
          },
          {
            authObject: 'INTEGRATION_OBJECTS',
            score: 91,
            hide: false,
            items: [
              {
                authItemDisplayName: 'Customer MD',
                authItemValue: 'CustomerMD',
                accesses: [
                  {
                    accessId: 37,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [37],
                    isDefault: false,
                  },
                  {
                    accessId: 40,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [37, 40],
                    isDefault: false,
                  },
                  {
                    accessId: 38,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [37, 38, 40],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'agreement search data',
                authItemValue: 'agreementsearchdata',
                accesses: [
                  {
                    accessId: 87,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [87],
                    isDefault: false,
                  },
                  {
                    accessId: 84,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [84],
                    isDefault: false,
                  },
                  {
                    accessId: 83,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [83, 84],
                    isDefault: false,
                  },
                  {
                    accessId: 82,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [82, 83, 84],
                    isDefault: false,
                  },
                  {
                    accessId: 85,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [82, 83, 84, 85, 87],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'agreement master data',
                authItemValue: 'agreementmasterdata',
                accesses: [
                  {
                    accessId: 51,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [51],
                    isDefault: false,
                  },
                  {
                    accessId: 49,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [49],
                    isDefault: false,
                  },
                  {
                    accessId: 48,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [48, 49],
                    isDefault: false,
                  },
                  {
                    accessId: 46,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [48, 49, 46],
                    isDefault: false,
                  },
                  {
                    accessId: 47,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [48, 49, 51, 46, 47],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'product master data',
                authItemValue: 'productmasterdata',
                accesses: [
                  {
                    accessId: 69,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [69],
                    isDefault: false,
                  },
                  {
                    accessId: 67,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [67],
                    isDefault: false,
                  },
                  {
                    accessId: 66,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [66, 67],
                    isDefault: false,
                  },
                  {
                    accessId: 64,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [64, 66, 67],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'hierarchy master data',
                authItemValue: 'hierarchymasterdata',
                accesses: [
                  {
                    accessId: 63,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [63],
                    isDefault: false,
                  },
                  {
                    accessId: 61,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [61],
                    isDefault: false,
                  },
                  {
                    accessId: 60,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [60, 61],
                    isDefault: false,
                  },
                  {
                    accessId: 58,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [58, 60, 61],
                    isDefault: false,
                  },
                  {
                    accessId: 59,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [58, 59, 60, 61, 63],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'customer master data',
                authItemValue: 'customermasterdata',
                accesses: [
                  {
                    accessId: 57,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [57],
                    isDefault: false,
                  },
                  {
                    accessId: 55,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [55],
                    isDefault: false,
                  },
                  {
                    accessId: 54,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [54, 55],
                    isDefault: false,
                  },
                  {
                    accessId: 52,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [52, 54, 55],
                    isDefault: false,
                  },
                  {
                    accessId: 53,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [52, 53, 54, 55, 57],
                    isDefault: false,
                  },
                ],
              },
            ],
          },
          {
            authObject: 'SETTINGS',
            score: 94,
            hide: false,
            items: [
              {
                authItemDisplayName: 'Basic Setup',
                authItemValue: 'BasicSetup',
                accesses: [
                  {
                    accessId: 25,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [25],
                    isDefault: false,
                  },
                  {
                    accessId: 28,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [25, 28],
                    isDefault: false,
                  },
                  {
                    accessId: 26,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [25, 26, 28],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'Users and Role Permission',
                authItemValue: 'UsersandRolePermission',
                accesses: [
                  {
                    accessId: 1,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [1],
                    isDefault: false,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: 11,
        name: 'autoTest-lynn03',
        description: 'test for full authorization',
        permissionItem: [
          {
            authObject: 'BUSINESS_OBJECTS',
            score: 90,
            hide: false,
            items: [
              {
                authItemDisplayName: 'Due List',
                authItemValue: 'DueList',
                accesses: [
                  {
                    accessId: 13,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [13],
                    isDefault: false,
                  },
                  {
                    accessId: 16,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [16, 13],
                    isDefault: false,
                  },
                  {
                    accessId: 14,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [16, 13, 14],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'Line Item',
                authItemValue: 'LineItem',
                accesses: [
                  {
                    accessId: 110,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [110],
                    isDefault: false,
                  },
                  {
                    accessId: 107,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [107],
                    isDefault: false,
                  },
                  {
                    accessId: 106,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [106, 107],
                    isDefault: false,
                  },
                  {
                    accessId: 108,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [106, 107, 108, 110],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'Settlement Run',
                authItemValue: 'SettlementRun',
                accesses: [
                  {
                    accessId: 9,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [9],
                    isDefault: false,
                  },
                  {
                    accessId: 12,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [9, 12],
                    isDefault: false,
                  },
                  {
                    accessId: 10,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [9, 10, 12],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'document',
                authItemValue: 'document',
                accesses: [
                  {
                    accessId: 116,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [116],
                    isDefault: false,
                  },
                  {
                    accessId: 112,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [112],
                    isDefault: false,
                  },
                  {
                    accessId: 114,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [112, 114],
                    isDefault: false,
                  },
                  {
                    accessId: 111,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [112, 114, 111],
                    isDefault: false,
                  },
                  {
                    accessId: 113,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [112, 113, 114, 116, 111],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'Debit Note',
                authItemValue: 'DebitNote',
                accesses: [
                  {
                    accessId: 105,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [105],
                    isDefault: false,
                  },
                  {
                    accessId: 102,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [102],
                    isDefault: false,
                  },
                  {
                    accessId: 101,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [101, 102],
                    isDefault: false,
                  },
                  {
                    accessId: 103,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [101, 102, 103, 105],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'Approval',
                authItemValue: 'Approval',
                accesses: [
                  {
                    accessId: 94,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [94],
                    isDefault: false,
                  },
                  {
                    accessId: 90,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [90],
                    isDefault: false,
                  },
                  {
                    accessId: 91,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [90, 91],
                    isDefault: false,
                  },
                  {
                    accessId: 89,
                    accessName: 'APPROVE',
                    accessDisplayName: 'Approve',
                    subAccessId: [89, 90, 91],
                    isDefault: false,
                  },
                  {
                    accessId: 88,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [88, 90, 91],
                    isDefault: false,
                  },
                  {
                    accessId: 92,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [88, 89, 90, 91, 92, 94],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'Claims Settlement',
                authItemValue: 'ClaimsSettlement',
                accesses: [
                  {
                    accessId: 5,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [5],
                    isDefault: false,
                  },
                  {
                    accessId: 8,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [5, 8],
                    isDefault: false,
                  },
                  {
                    accessId: 6,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [5, 6, 8],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'Claim',
                authItemValue: 'Claim',
                accesses: [
                  {
                    accessId: 136,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [136],
                    isDefault: false,
                  },
                  {
                    accessId: 135,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [135],
                    isDefault: false,
                  },
                  {
                    accessId: 140,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [135, 140],
                    isDefault: false,
                  },
                  {
                    accessId: 138,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [135, 138, 140],
                    isDefault: false,
                  },
                  {
                    accessId: 139,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [135, 136, 138, 139, 140],
                    isDefault: false,
                  },
                ],
              },
            ],
          },
          {
            authObject: 'INTEGRATION_OBJECTS',
            score: 91,
            hide: false,
            items: [
              {
                authItemDisplayName: 'Customer MD',
                authItemValue: 'CustomerMD',
                accesses: [
                  {
                    accessId: 37,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [37],
                    isDefault: false,
                  },
                  {
                    accessId: 40,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [37, 40],
                    isDefault: false,
                  },
                  {
                    accessId: 38,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [37, 38, 40],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'agreement search data',
                authItemValue: 'agreementsearchdata',
                accesses: [
                  {
                    accessId: 87,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [87],
                    isDefault: false,
                  },
                  {
                    accessId: 84,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [84],
                    isDefault: false,
                  },
                  {
                    accessId: 83,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [83, 84],
                    isDefault: false,
                  },
                  {
                    accessId: 82,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [82, 83, 84],
                    isDefault: false,
                  },
                  {
                    accessId: 85,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [82, 83, 84, 85, 87],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'validated amount',
                authItemValue: 'validatedamount',
                accesses: [
                  {
                    accessId: 81,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [81],
                    isDefault: false,
                  },
                  {
                    accessId: 79,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [79],
                    isDefault: false,
                  },
                  {
                    accessId: 78,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [78, 79],
                    isDefault: false,
                  },
                  {
                    accessId: 76,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [76, 78, 79],
                    isDefault: false,
                  },
                  {
                    accessId: 77,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [81, 76, 77, 78, 79],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'AR open item',
                authItemValue: 'ARopenitem',
                accesses: [
                  {
                    accessId: 29,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [29],
                    isDefault: false,
                  },
                  {
                    accessId: 32,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [32, 29],
                    isDefault: false,
                  },
                  {
                    accessId: 30,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [32, 29, 30],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'promotion master data',
                authItemValue: 'promotionmasterdata',
                accesses: [
                  {
                    accessId: 75,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [75],
                    isDefault: false,
                  },
                  {
                    accessId: 73,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [73],
                    isDefault: false,
                  },
                  {
                    accessId: 72,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [72, 73],
                    isDefault: false,
                  },
                  {
                    accessId: 70,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [70, 72, 73],
                    isDefault: false,
                  },
                  {
                    accessId: 71,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [70, 71, 72, 73, 75],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'uom service data',
                authItemValue: 'uomservicedata',
                accesses: [
                  {
                    accessId: 128,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [128],
                    isDefault: false,
                  },
                  {
                    accessId: 125,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [125],
                    isDefault: false,
                  },
                  {
                    accessId: 124,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [124, 125],
                    isDefault: false,
                  },
                  {
                    accessId: 123,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [123, 124, 125],
                    isDefault: false,
                  },
                  {
                    accessId: 126,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [128, 123, 124, 125, 126],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'agreement master data',
                authItemValue: 'agreementmasterdata',
                accesses: [
                  {
                    accessId: 51,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [51],
                    isDefault: false,
                  },
                  {
                    accessId: 49,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [49],
                    isDefault: false,
                  },
                  {
                    accessId: 48,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [48, 49],
                    isDefault: false,
                  },
                  {
                    accessId: 46,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [48, 49, 46],
                    isDefault: false,
                  },
                  {
                    accessId: 47,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [48, 49, 51, 46, 47],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'product master data',
                authItemValue: 'productmasterdata',
                accesses: [
                  {
                    accessId: 69,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [69],
                    isDefault: false,
                  },
                  {
                    accessId: 67,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [67],
                    isDefault: false,
                  },
                  {
                    accessId: 64,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [64, 66, 67],
                    isDefault: false,
                  },
                  {
                    accessId: 65,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [64, 65, 66, 67, 69],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'hierarchy master data',
                authItemValue: 'hierarchymasterdata',
                accesses: [
                  {
                    accessId: 63,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [63],
                    isDefault: false,
                  },
                  {
                    accessId: 61,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [61],
                    isDefault: false,
                  },
                  {
                    accessId: 60,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [60, 61],
                    isDefault: false,
                  },
                  {
                    accessId: 58,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [58, 60, 61],
                    isDefault: false,
                  },
                  {
                    accessId: 59,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [58, 59, 60, 61, 63],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'sales area',
                authItemValue: 'salesarea',
                accesses: [
                  {
                    accessId: 122,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [122],
                    isDefault: false,
                  },
                  {
                    accessId: 119,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [119],
                    isDefault: false,
                  },
                  {
                    accessId: 118,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [118, 119],
                    isDefault: false,
                  },
                  {
                    accessId: 117,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [117, 118, 119],
                    isDefault: false,
                  },
                  {
                    accessId: 120,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [117, 118, 119, 120, 122],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'customer master data',
                authItemValue: 'customermasterdata',
                accesses: [
                  {
                    accessId: 57,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [57],
                    isDefault: false,
                  },
                  {
                    accessId: 55,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [55],
                    isDefault: false,
                  },
                  {
                    accessId: 54,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [54, 55],
                    isDefault: false,
                  },
                  {
                    accessId: 52,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [52, 54, 55],
                    isDefault: false,
                  },
                  {
                    accessId: 53,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [52, 53, 54, 55, 57],
                    isDefault: false,
                  },
                ],
              },
            ],
          },
          {
            authObject: 'SETTINGS',
            score: 94,
            hide: false,
            items: [
              {
                authItemDisplayName: 'Company Profile',
                authItemValue: 'CompanyProfile',
                accesses: [
                  {
                    accessId: 21,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [21],
                    isDefault: false,
                  },
                  {
                    accessId: 24,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [21, 24],
                    isDefault: false,
                  },
                  {
                    accessId: 22,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [21, 22, 24],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'Basic Setup',
                authItemValue: 'BasicSetup',
                accesses: [
                  {
                    accessId: 25,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [25],
                    isDefault: false,
                  },
                  {
                    accessId: 28,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [25, 28],
                    isDefault: false,
                  },
                  {
                    accessId: 26,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [25, 26, 28],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'Reason Code Configuration',
                authItemValue: 'ReasonCodeConfiguration',
                accesses: [
                  {
                    accessId: 33,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [33],
                    isDefault: false,
                  },
                  {
                    accessId: 36,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [33, 36],
                    isDefault: false,
                  },
                  {
                    accessId: 34,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [33, 34, 36],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'Users and Role Permission',
                authItemValue: 'UsersandRolePermission',
                accesses: [
                  {
                    accessId: 1,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [1],
                    isDefault: false,
                  },
                  {
                    accessId: 4,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [1, 4],
                    isDefault: false,
                  },
                  {
                    accessId: 2,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [1, 2, 4],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'Responsibility',
                authItemValue: 'Responsibility',
                accesses: [
                  {
                    accessId: 41,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [41],
                    isDefault: false,
                  },
                  {
                    accessId: 45,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [41, 45],
                    isDefault: false,
                  },
                  {
                    accessId: 42,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [41, 42, 45],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'Run Config',
                authItemValue: 'RunConfig',
                accesses: [
                  {
                    accessId: 17,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [17],
                    isDefault: false,
                  },
                  {
                    accessId: 20,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [17, 20],
                    isDefault: false,
                  },
                  {
                    accessId: 18,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [17, 18, 20],
                    isDefault: false,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: 20,
        name: 'User and Role Read - 1126',
        description: 'Test Role _lynn',
        permissionItem: [
          {
            authObject: 'BUSINESS_OBJECTS',
            score: 90,
            hide: false,
            items: [
              {
                authItemDisplayName: 'Due List',
                authItemValue: 'DueList',
                accesses: [
                  {
                    accessId: 13,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [13],
                    isDefault: false,
                  },
                  {
                    accessId: 16,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [16, 13],
                    isDefault: false,
                  },
                  {
                    accessId: 14,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [16, 13, 14],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'Line Item',
                authItemValue: 'LineItem',
                accesses: [
                  {
                    accessId: 110,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [110],
                    isDefault: false,
                  },
                  {
                    accessId: 107,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [107],
                    isDefault: false,
                  },
                  {
                    accessId: 106,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [106, 107],
                    isDefault: false,
                  },
                  {
                    accessId: 108,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [106, 107, 108, 110],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'Settlement Run',
                authItemValue: 'SettlementRun',
                accesses: [
                  {
                    accessId: 9,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [9],
                    isDefault: false,
                  },
                  {
                    accessId: 12,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [9, 12],
                    isDefault: false,
                  },
                  {
                    accessId: 10,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [9, 10, 12],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'document',
                authItemValue: 'document',
                accesses: [
                  {
                    accessId: 116,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [116],
                    isDefault: false,
                  },
                  {
                    accessId: 112,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [112],
                    isDefault: false,
                  },
                  {
                    accessId: 114,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [112, 114],
                    isDefault: false,
                  },
                  {
                    accessId: 111,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [112, 114, 111],
                    isDefault: false,
                  },
                  {
                    accessId: 113,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [112, 113, 114, 116, 111],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'Debit Note',
                authItemValue: 'DebitNote',
                accesses: [
                  {
                    accessId: 105,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [105],
                    isDefault: false,
                  },
                  {
                    accessId: 102,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [102],
                    isDefault: false,
                  },
                  {
                    accessId: 101,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [101, 102],
                    isDefault: false,
                  },
                  {
                    accessId: 103,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [101, 102, 103, 105],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'Approval',
                authItemValue: 'Approval',
                accesses: [
                  {
                    accessId: 94,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [94],
                    isDefault: false,
                  },
                  {
                    accessId: 90,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [90],
                    isDefault: false,
                  },
                  {
                    accessId: 91,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [90, 91],
                    isDefault: false,
                  },
                  {
                    accessId: 89,
                    accessName: 'APPROVE',
                    accessDisplayName: 'Approve',
                    subAccessId: [89, 90, 91],
                    isDefault: false,
                  },
                  {
                    accessId: 88,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [88, 90, 91],
                    isDefault: false,
                  },
                  {
                    accessId: 92,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [88, 89, 90, 91, 92, 94],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'Claims Settlement',
                authItemValue: 'ClaimsSettlement',
                accesses: [
                  {
                    accessId: 5,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [5],
                    isDefault: false,
                  },
                  {
                    accessId: 8,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [5, 8],
                    isDefault: false,
                  },
                  {
                    accessId: 6,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [5, 6, 8],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'Claim',
                authItemValue: 'Claim',
                accesses: [
                  {
                    accessId: 136,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [136],
                    isDefault: false,
                  },
                  {
                    accessId: 135,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [135],
                    isDefault: false,
                  },
                  {
                    accessId: 140,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [135, 140],
                    isDefault: false,
                  },
                  {
                    accessId: 138,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [135, 138, 140],
                    isDefault: false,
                  },
                  {
                    accessId: 139,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [135, 136, 138, 139, 140],
                    isDefault: false,
                  },
                ],
              },
            ],
          },
          {
            authObject: 'INTEGRATION_OBJECTS',
            score: 91,
            hide: false,
            items: [
              {
                authItemDisplayName: 'Customer MD',
                authItemValue: 'CustomerMD',
                accesses: [
                  {
                    accessId: 37,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [37],
                    isDefault: false,
                  },
                  {
                    accessId: 40,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [37, 40],
                    isDefault: false,
                  },
                  {
                    accessId: 38,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [37, 38, 40],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'agreement search data',
                authItemValue: 'agreementsearchdata',
                accesses: [
                  {
                    accessId: 87,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [87],
                    isDefault: false,
                  },
                  {
                    accessId: 84,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [84],
                    isDefault: false,
                  },
                  {
                    accessId: 83,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [83, 84],
                    isDefault: false,
                  },
                  {
                    accessId: 82,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [82, 83, 84],
                    isDefault: false,
                  },
                  {
                    accessId: 85,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [82, 83, 84, 85, 87],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'validated amount',
                authItemValue: 'validatedamount',
                accesses: [
                  {
                    accessId: 81,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [81],
                    isDefault: false,
                  },
                  {
                    accessId: 79,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [79],
                    isDefault: false,
                  },
                  {
                    accessId: 78,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [78, 79],
                    isDefault: false,
                  },
                  {
                    accessId: 76,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [76, 78, 79],
                    isDefault: false,
                  },
                  {
                    accessId: 77,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [81, 76, 77, 78, 79],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'AR open item',
                authItemValue: 'ARopenitem',
                accesses: [
                  {
                    accessId: 29,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [29],
                    isDefault: false,
                  },
                  {
                    accessId: 32,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [32, 29],
                    isDefault: false,
                  },
                  {
                    accessId: 30,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [32, 29, 30],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'promotion master data',
                authItemValue: 'promotionmasterdata',
                accesses: [
                  {
                    accessId: 75,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [75],
                    isDefault: false,
                  },
                  {
                    accessId: 73,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [73],
                    isDefault: false,
                  },
                  {
                    accessId: 72,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [72, 73],
                    isDefault: false,
                  },
                  {
                    accessId: 70,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [70, 72, 73],
                    isDefault: false,
                  },
                  {
                    accessId: 71,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [70, 71, 72, 73, 75],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'uom service data',
                authItemValue: 'uomservicedata',
                accesses: [
                  {
                    accessId: 128,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [128],
                    isDefault: false,
                  },
                  {
                    accessId: 125,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [125],
                    isDefault: false,
                  },
                  {
                    accessId: 124,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [124, 125],
                    isDefault: false,
                  },
                  {
                    accessId: 123,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [123, 124, 125],
                    isDefault: false,
                  },
                  {
                    accessId: 126,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [128, 123, 124, 125, 126],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'agreement master data',
                authItemValue: 'agreementmasterdata',
                accesses: [
                  {
                    accessId: 51,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [51],
                    isDefault: false,
                  },
                  {
                    accessId: 49,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [49],
                    isDefault: false,
                  },
                  {
                    accessId: 48,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [48, 49],
                    isDefault: false,
                  },
                  {
                    accessId: 46,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [48, 49, 46],
                    isDefault: false,
                  },
                  {
                    accessId: 47,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [48, 49, 51, 46, 47],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'product master data',
                authItemValue: 'productmasterdata',
                accesses: [
                  {
                    accessId: 69,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [69],
                    isDefault: false,
                  },
                  {
                    accessId: 67,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [67],
                    isDefault: false,
                  },
                  {
                    accessId: 66,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [66, 67],
                    isDefault: false,
                  },
                  {
                    accessId: 64,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [64, 66, 67],
                    isDefault: false,
                  },
                  {
                    accessId: 65,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [64, 65, 66, 67, 69],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'hierarchy master data',
                authItemValue: 'hierarchymasterdata',
                accesses: [
                  {
                    accessId: 63,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [63],
                    isDefault: false,
                  },
                  {
                    accessId: 61,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [61],
                    isDefault: false,
                  },
                  {
                    accessId: 60,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [60, 61],
                    isDefault: false,
                  },
                  {
                    accessId: 59,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [58, 59, 60, 61, 63],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'sales area',
                authItemValue: 'salesarea',
                accesses: [
                  {
                    accessId: 122,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [122],
                    isDefault: false,
                  },
                  {
                    accessId: 119,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [119],
                    isDefault: false,
                  },
                  {
                    accessId: 118,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [118, 119],
                    isDefault: false,
                  },
                  {
                    accessId: 117,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [117, 118, 119],
                    isDefault: false,
                  },
                  {
                    accessId: 120,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [117, 118, 119, 120, 122],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'customer master data',
                authItemValue: 'customermasterdata',
                accesses: [
                  {
                    accessId: 57,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [57],
                    isDefault: false,
                  },
                  {
                    accessId: 55,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [55],
                    isDefault: false,
                  },
                  {
                    accessId: 54,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [54, 55],
                    isDefault: false,
                  },
                  {
                    accessId: 52,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [52, 54, 55],
                    isDefault: false,
                  },
                  {
                    accessId: 53,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [52, 53, 54, 55, 57],
                    isDefault: false,
                  },
                ],
              },
            ],
          },
          {
            authObject: 'SETTINGS',
            score: 94,
            hide: false,
            items: [
              {
                authItemDisplayName: 'Company Profile',
                authItemValue: 'CompanyProfile',
                accesses: [
                  {
                    accessId: 23,
                    accessName: 'NO_AUTH',
                    accessDisplayName: 'No authorization',
                    subAccessId: [],
                    isDefault: true,
                  },
                ],
              },
              {
                authItemDisplayName: 'Basic Setup',
                authItemValue: 'BasicSetup',
                accesses: [
                  {
                    accessId: 27,
                    accessName: 'NO_AUTH',
                    accessDisplayName: 'No authorization',
                    subAccessId: [],
                    isDefault: true,
                  },
                ],
              },
              {
                authItemDisplayName: 'Reason Code Configuration',
                authItemValue: 'ReasonCodeConfiguration',
                accesses: [
                  {
                    accessId: 35,
                    accessName: 'NO_AUTH',
                    accessDisplayName: 'No authorization',
                    subAccessId: [],
                    isDefault: true,
                  },
                ],
              },
              {
                authItemDisplayName: 'Users and Role Permission',
                authItemValue: 'UsersandRolePermission',
                accesses: [
                  {
                    accessId: 1,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [1],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'Responsibility',
                authItemValue: 'Responsibility',
                accesses: [
                  {
                    accessId: 43,
                    accessName: 'NO_AUTH',
                    accessDisplayName: 'No authorization',
                    subAccessId: [],
                    isDefault: true,
                  },
                ],
              },
              {
                authItemDisplayName: 'Run Config',
                authItemValue: 'RunConfig',
                accesses: [
                  {
                    accessId: 19,
                    accessName: 'NO_AUTH',
                    accessDisplayName: 'No authorization',
                    subAccessId: [],
                    isDefault: true,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: 3,
        name: 'Claim Analyst Colgate',
        description: '(DO NOT CHANGE) Claim Analyst',
        permissionItem: [
          {
            authObject: 'BUSINESS_OBJECTS',
            score: 90,
            hide: false,
            items: [
              {
                authItemDisplayName: 'Due List',
                authItemValue: 'DueList',
                accesses: [
                  {
                    accessId: 13,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [13],
                    isDefault: false,
                  },
                  {
                    accessId: 16,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [16, 13],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'Line Item',
                authItemValue: 'LineItem',
                accesses: [
                  {
                    accessId: 110,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [110],
                    isDefault: false,
                  },
                  {
                    accessId: 107,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [107],
                    isDefault: false,
                  },
                  {
                    accessId: 106,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [106, 107],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'Settlement Run',
                authItemValue: 'SettlementRun',
                accesses: [
                  {
                    accessId: 9,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [9],
                    isDefault: false,
                  },
                  {
                    accessId: 12,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [9, 12],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'document',
                authItemValue: 'document',
                accesses: [
                  {
                    accessId: 116,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [116],
                    isDefault: false,
                  },
                  {
                    accessId: 112,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [112],
                    isDefault: false,
                  },
                  {
                    accessId: 114,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [112, 114],
                    isDefault: false,
                  },
                  {
                    accessId: 111,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [112, 114, 111],
                    isDefault: false,
                  },
                  {
                    accessId: 113,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [112, 113, 114, 116, 111],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'Debit Note',
                authItemValue: 'DebitNote',
                accesses: [
                  {
                    accessId: 105,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [105],
                    isDefault: false,
                  },
                  {
                    accessId: 102,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [102],
                    isDefault: false,
                  },
                  {
                    accessId: 101,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [101, 102],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'Approval',
                authItemValue: 'Approval',
                accesses: [
                  {
                    accessId: 94,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [94],
                    isDefault: false,
                  },
                  {
                    accessId: 90,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [90],
                    isDefault: false,
                  },
                  {
                    accessId: 91,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [90, 91],
                    isDefault: false,
                  },
                  {
                    accessId: 89,
                    accessName: 'APPROVE',
                    accessDisplayName: 'Approve',
                    subAccessId: [89, 90, 91],
                    isDefault: false,
                  },
                  {
                    accessId: 88,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [88, 90, 91],
                    isDefault: false,
                  },
                  {
                    accessId: 92,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [88, 89, 90, 91, 92, 94],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'Claims Settlement',
                authItemValue: 'ClaimsSettlement',
                accesses: [
                  {
                    accessId: 5,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [5],
                    isDefault: false,
                  },
                  {
                    accessId: 8,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [5, 8],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'Claim',
                authItemValue: 'Claim',
                accesses: [
                  {
                    accessId: 136,
                    accessName: 'CREATE',
                    accessDisplayName: 'Create',
                    subAccessId: [136],
                    isDefault: false,
                  },
                  {
                    accessId: 135,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [135],
                    isDefault: false,
                  },
                  {
                    accessId: 140,
                    accessName: 'WRITE',
                    accessDisplayName: 'Write',
                    subAccessId: [135, 140],
                    isDefault: false,
                  },
                  {
                    accessId: 138,
                    accessName: 'DELETE',
                    accessDisplayName: 'Delete',
                    subAccessId: [135, 138, 140],
                    isDefault: false,
                  },
                  {
                    accessId: 139,
                    accessName: 'FULL_AUTH',
                    accessDisplayName: 'Full authorization',
                    subAccessId: [135, 136, 138, 139, 140],
                    isDefault: false,
                  },
                ],
              },
            ],
          },
          {
            authObject: 'INTEGRATION_OBJECTS',
            score: 91,
            hide: false,
            items: [
              {
                authItemDisplayName: 'Customer MD',
                authItemValue: 'CustomerMD',
                accesses: [
                  {
                    accessId: 37,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [37],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'agreement search data',
                authItemValue: 'agreementsearchdata',
                accesses: [
                  {
                    accessId: 84,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [84],
                    isDefault: false,
                  },
                ],
              },
              {
                authItemDisplayName: 'promotion master data',
                authItemValue: 'promotionmasterdata',
                accesses: [
                  {
                    accessId: 73,
                    accessName: 'READ',
                    accessDisplayName: 'Read',
                    subAccessId: [73],
                    isDefault: false,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};
export const setting5 = {
  data: {
    resultList: [
      { feature: 'feature-bbbbb', isEnable: false },
      { feature: 'agreement-search-getAgreements', isEnable: false },
      { feature: 'validated-amount-maple-leaf-ITCM-4511', isEnable: false },
      { feature: 'settlement-test1', isEnable: false },
      { feature: 'my-fancy-feature', isEnable: false },
      { feature: 'python-frw-test', isEnable: true },
      { feature: 'feature-aaa', isEnable: false },
    ],
  },
};
