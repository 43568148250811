/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-var */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import CacheRoute from 'react-router-cache-route';

import eurekaMgrs from '@eureka/ui-managers';

const children = []; // children component list
const { eventBus } = eurekaMgrs;

export const rawSetting = { basicSetup: {}, userProfile: {}, companyProfile: {} };
export const mergeSettings = (rawSetting) => {
  const { basicSetup, userProfile, companyProfile } = rawSetting;
  const settings = { ...basicSetup, ...companyProfile };
  delete settings.id;
  if (parseFloat(userProfile.userUpdateState) > 0) {
    Object.keys(userProfile).forEach((key) => {
      if (userProfile[key]) {
        settings[key] = userProfile[key];
      }
    });
  }
  settings.__raw = { ...rawSetting };
  return settings;
};

export const onbeforeunloadHandler = (event) => {
  const userId = '';
  const swa = window.swa || {};
  swa.custom1 = { ref: userId };
  swa.custom2 = { ref: 'claims-invoice' };
  if (swa && swa.hasOwnProperty('trackCustomEvent')) {
    swa.trackCustomEvent(
      'claims',
      [
        'user_refresh',
        userId,
        String(window.location.href),
        String(window.location.host),
        String(window.location.pathname),
        '1',
        userId,
      ],
      [1],
    );
  }
};

window.onbeforeunload = onbeforeunloadHandler;

export function trackRouterNavigation(idOrText, duration, clickCount) {
  const userId = 'uuid';
  const swa = window.swa || {};
  swa.custom2 = { ref: 'claims-invoice' };
  if (swa && swa.hasOwnProperty('trackCustomEvent')) {
    swa.trackCustomEvent(
      'claims',
      ['navigation_route', idOrText, userId, String(duration), String(clickCount), userId],
      [duration, clickCount],
    );
  }
}

export function renderRouteConfigV3(routes, contextPath, config, settings, user) {
  // Resolve route config object in React Router v3.

  const renderRoute = (item, routeContextPath) => {
    let newContextPath;
    if (/^\//.test(item.path)) {
      newContextPath = item.path;
    } else {
      newContextPath = `${routeContextPath}/${item.path}`;
    }
    newContextPath = newContextPath.replace(/\/+/g, '/');
    if (item.component && item.childRoutes) {
      const childRoutes = renderRouteConfigV3(
        item.childRoutes,
        newContextPath,
        config,
        settings,
        user,
      );
      children.push(
        <CacheRoute
          key={newContextPath}
          render={(props) => (
            <item.component
              {...props}
              config={config}
              settings={settings}
              user={user}
              eventBus={eventBus}
            >
              {childRoutes}
            </item.component>
          )}
          path={newContextPath}
        />,
      );
    } else if (item.component) {
      children.push(
        <CacheRoute
          key={newContextPath}
          component={(props) => (
            <item.component
              {...props}
              config={config}
              settings={settings}
              user={user}
              eventBus={eventBus}
            />
          )}
          path={newContextPath}
          exact
        />,
      );
    } else if (item.childRoutes) {
      item.childRoutes.forEach((r) => renderRoute(r, newContextPath));
    }
  };

  routes.forEach((item) => renderRoute(item, contextPath));

  // Use Switch so that only the first matched route is rendered.
  return children;
}

export const locationChangeHandler = (location) => {
  let entryTime = Date.now();
  let currentPathName = window.location.pathname;
  const now = Date.now();
  trackRouterNavigation(currentPathName, now - entryTime, 1);
  entryTime = now;
  currentPathName = location.pathname;
};

export const notEmptyStr = (str) => str && str !== '';

export const listenToEventBus = ({ rawSettings, state, setState, history }) => {
  eventBus.on('configuration-updated', (message) => {
    if (notEmptyStr(message.key)) {
      rawSettings.current[message.key] = message.data;
      setState({
        ...state,
        settings: mergeSettings(rawSettings.current),
        user:
          message.key === 'userProfile'
            ? JSON.parse(JSON.stringify(message.data))
            : {
                ...state.user,
              },
      });
    }
  });

  // eslint-disable-next-line @typescript-eslint/no-shadow
  eventBus.on('navigate-page', (path, state) => {
    if (path) {
      history.push(path, state);
    }
  });
};
