import {
  COMMON_SHOW_MENU,
  COMMON_HIDE_MENU,
} from './constants'

export function showMenu () {
  return dispatch => {
    dispatch({
      type: COMMON_SHOW_MENU,
      data: true
    })
  }
}

export function hideMenu () {
  return dispatch => {
    dispatch({
      type: COMMON_HIDE_MENU,
      data: false
    })
  }
}

export function reducer (state, action) {
  switch (action.type) {
    case COMMON_SHOW_MENU:
      return {
        ...state,
        showMenu: action.data,
      }
    case COMMON_HIDE_MENU:
      return {
        ...state,
        showMenu: action.data,
      }
    default:
      return state
  }
}
