import React from 'react';
import ErrorComponent from '../common/ErrorComponent';

export default props => {
  const errorType = props.match.params.id;
  const query = window.location.search;
  let errorCode = '';
  if (query) errorCode = decodeURIComponent(query.split('=')[1]);

  return (
    <ErrorComponent props={props} errorType={errorType} errorCode={errorCode}></ErrorComponent>
  );
};
