import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';

import Header from './Header';
import SidePanel from './SidePanel';
import { ShellDialog } from '../plugins/dialog.plugin';
import MessageToast from '../plugins/toast.plugin';
import OptionalMessageBox from '../plugins/messagebox.plugin';
import EurekaComponents from 'eureka';
const { Spinner } = EurekaComponents.components;

export class DefaultLayout extends Component {
  static propTypes = {
    children: PropTypes.node,
    history: PropTypes.object,
    settings: PropTypes.object.isRequired,
  };

  render() {
    const { showMenu } = this.props.common;
    return (
      <div className="fd-shell fd-shell--fundamentals">
        <div style={{ background: 'var(--sapShellColor)' }}>
          <Suspense fallback={<Spinner />}>
            <Header
              history={this.props.history}
              config={this.props.config}
              settings={this.props.settings}
              user={this.props.user}
              common={this.props.common}
            />
          </Suspense>
        </div>
        <div className="fd-shell__app">
          <div className="fd-app">
            <div
              className={
                'fd-app__navigation fd-app__navigation--vertical' +
                (showMenu ? ' show-menu ' : ' hide-menu ')
              }
            >
              <Suspense fallback={<Spinner />}>
                <SidePanel
                  history={this.props.history}
                  match={this.props.match}
                  config={this.props.config}
                />
              </Suspense>
            </div>
            <main className="fd-app__main">
              <Suspense fallback={<Spinner cssClass="page-load-spinner" />}>
                {this.props.children}
              </Suspense>
            </main>
          </div>
        </div>
        <ShellDialog key="default-layout-dialog" />
        <MessageToast key="default-message-toast" />
        <OptionalMessageBox />
        {/* <div className="fd-shell__footer"><span>SAP</span></div> */}
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    common: state.common,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
