/* eslint-disable operator-linebreak */
import React, { useEffect, useRef } from 'react';
import { SideNavigation } from '@ui5/webcomponents-react/dist/SideNavigation';

let EventMap = {};

let TreeNodeTag = 'ui5-tree';
let ListNodeTag = 'ui5-list';
let LiTreeTag = 'ui5-li-tree';
let IconTag = 'ui5-icon';
if (process.env.NODE_ENV !== 'test') {
  TreeNodeTag = `ui5-tree-${process.env.APP_NAME}`;
  ListNodeTag = `ui5-list-${process.env.APP_NAME}`;
  LiTreeTag = `ui5-li-tree-${process.env.APP_NAME}`;
  IconTag = `ui5-icon-${process.env.APP_NAME}`;
}

const getTreeRoot = (currentDom) => currentDom?.shadowRoot?.querySelector(`.ui5-sn-root ${TreeNodeTag}`);

const attachEventsForAll = (currentDom, clickHandler) => {
  const treeRoot = getTreeRoot(currentDom);
  const firstLevelItems = treeRoot?.shadowRoot?.querySelectorAll(`${ListNodeTag} > ${LiTreeTag}`);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < firstLevelItems.length; i++) {
    const item = firstLevelItems[i];
    if (item) {
      const { nodeName } = item;
      if (nodeName === LiTreeTag.toUpperCase()) {
        // eslint-disable-next-line no-underscore-dangle
        EventMap[item.__id] = {
          item,
          clickHandler,
        };
        item.onclick = clickHandler;
      }
    }
  }
};

const detachEventsForAll = () => {
  Object.keys(EventMap).forEach((key) => {
    const entry = EventMap[key];
    if (entry.item) {
      entry.item.onclick = null;
    }
  });
  EventMap = {};
};

const SideNavigationObserver = (props) => {
  const item = useRef();
  const clickHandler = (evt) => {
    const { target } = evt;
    if (
      target?.shadowRoot &&
      target?.icon &&
      target?.level === 1 &&
      !!target?.shadowRoot?.querySelector(`${IconTag}.ui5-li-tree-toggle-icon`)
    ) {
      const foldIcon = target.shadowRoot.querySelector(`${IconTag}.ui5-li-tree-toggle-icon`);
      if (foldIcon) {
        foldIcon.fireEvent('click');
      }
    }
  };

  const ui5TreeCallback = () => {
    detachEventsForAll();
    setTimeout(() => {
      attachEventsForAll(item.current, clickHandler);
    }, 1);
  };

  useEffect(() => {
    const currentDom = item.current;
    let observer = null;
    let token = 0;

    if (currentDom && currentDom.shadowRoot) {
      token = setTimeout(() => {
        const treeRoot = currentDom.shadowRoot.querySelector(`.ui5-sn-root ${TreeNodeTag}`);
        if (treeRoot) {
          const config = { attributes: true, childList: true, subtree: true };
          observer = new MutationObserver(ui5TreeCallback);
          observer.observe(treeRoot, config);
          attachEventsForAll(item.current, clickHandler);
        }
      }, 0);
    }
    return () => {
      detachEventsForAll();
      observer?.disconnect();
      clearTimeout(token);
    };
  });
  // eslint-disable-next-line react/jsx-filename-extension
  return <SideNavigation ref={item} {...props} />;
};

export default SideNavigationObserver;
