import { getRandom } from '../features/common/Utils';
import React, { useEffect, useRef } from 'react';
import EurekaManagers from '@eureka/ui-managers';

const { FragmentManager } = EurekaManagers;

export const loadCSS = (dom, assetName, host, name, filePath, hash, callback) => {
  // load css styles
  // console.log(assetName, filePath, hash);

  const buildCssLink = (resolve, reject) => {
    const styleId = `${assetName}-style-${name.toLowerCase()}`;
    const style = dom.getElementById(styleId);
    if (!style) {
      const cssLink = dom.createElement('link');
      cssLink.id = styleId;
      cssLink.rel = 'stylesheet';
      cssLink.type = 'text/css';
      cssLink.crossOrigin = 'anonymous';
      cssLink.href = `${host}${filePath}?random=${getRandom()}`;
      if (hash) {
        cssLink.integrity = hash;
      }
      cssLink.onload = () => resolve();
      cssLink.onerror = (err) => reject(err);
      dom.head.appendChild(cssLink);
    } else {
      resolve();
    }
  };

  return new Promise((resolve, reject) => {
    buildCssLink(resolve, reject);
  });
};

export function getURLParam(url, name) {
  const searchParams = new URLSearchParams(url);
  return searchParams.get(name);
}

// 1Q = en-US-saptrc;
// 2Q = en-US-sappsd;
export const TestingLocales = {
  '1Q': 'en-US-saptrc',
  '2Q': 'en-US-sappsd',
};

export const constructPermissions = (rolePermission) => {
  const permissions = [];
  (rolePermission || [])
    .map((r) => r.permissionItem)
    .forEach((p) => {
      p.forEach((o) => {
        o.items.forEach((i) => {
          i.accesses.forEach((access) => {
            const authItem = `${i.authItemValue}.${access.accessName}`.toUpperCase();
            if (!permissions.includes(authItem)) {
              permissions.push(authItem);
            }
          });
        });
      });
    });

  return permissions;
};

/**
 * function to remove a MFE Component from Side-nav list if FF is disabled
 * @param {Array}: input array containing list of components/MFEs
 * @param {String}: name of components/MFE to be shown/hidden
 * @param {Boolean}: feature flag
 * @returns {Array}: output array containing list of components/MFEs to be displayed in side-nav
 * */
/* istanbul ignore next */
export function removeFeatureFlaggedComponent(componentList, componentName, featureFlag) {
  if (!componentName || featureFlag === undefined) {
    return componentList;
  }
  // if FF is disabled, remove component from the list and return remaining list
  if (!featureFlag) {
    componentList = componentList.filter((item) => item.id !== componentName);
  }
  return componentList;
}

export const FragmentBlock = (config, history, settings, eventBus, fragmentName) => {
  const domRef = useRef();
  useEffect(() => {
    let fragmentsDetails = [];
    const container = domRef.current;
    let renderMap = null;
    if (container) {
      const fragmentResolve = FragmentManager.resolveTagFragments(fragmentName, config);
      Promise.all(fragmentResolve).then(() => {
        fragmentsDetails = FragmentManager.getFragmentsDetailWithTag(fragmentName, config);
        renderMap = FragmentManager.renderFragments({
          container,
          fragmentsDetails,
          renderFragment: (fragmentContainer, fragment) => {
            fragment.render(fragmentContainer, history, {
              config,
              history,
              settings,
              eventBus,
            });
          },
        });
      });
    }
    return () => {
      if (container && renderMap) {
        FragmentManager.unmountFragments({ container, renderMap });
      }
    };
  }, [config, history, settings, eventBus, fragmentName]);
  return <div ref={domRef} />;
};
