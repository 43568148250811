import eurekaMgrs from '@eureka/ui-managers';
import '@ui5/webcomponents-icons/dist/copy';
import '@ui5/webcomponents-icons/dist/json-imports/Icons.js';
import '@ui5/webcomponents-icons/dist/log.js';
import '@ui5/webcomponents-icons/dist/menu';
import '@ui5/webcomponents-icons/dist/menu2';
import '@ui5/webcomponents-icons/dist/product';
import '@ui5/webcomponents-icons/dist/user-settings.js';
import {
  Button,
  ButtonDesign,
  Input,
  Label,
  Popover,
  ShellBar,
  StandardListItem,
} from '@ui5/webcomponents-react';
import eureka from 'eureka';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LogoutForm from '../plugins/LogoutForm';
import { getAvatar } from '../plugins/profile.plugin';
import { searchInfo } from '../plugins/search.plugin';
import CustomNotification from './CustomNotification';
// import '@ui5/webcomponents/dist/features/InputSuggestions.js';
import logo from './logo';
import * as actions from './redux/actions';
import { FragmentBlock } from '../../common/Utils';

const { FragmentManager } = eurekaMgrs;
const { withTranslation } = eureka.I18nProvider;
const { eventBus } = eurekaMgrs;
const { addConfig } = eurekaMgrs.ConfigManager;
const { Spinner } = eureka.components;
const UserProfileBlock = ({ config, history, settings, eventBus }) => {
  return FragmentBlock(config, history, settings, eventBus, 'shell-user_profile');
};

/* istanbul ignore next */
// export const UserProfileFragment = ({ config, history }) => {
//   const domRef = useRef();
//   useEffect(() => {
//     const container = domRef.current;
//     const fragments = FragmentManager.getFragmentsWithTag('shell-user_profile', config);
//     let renderMap = null;
//     if (container) {
//       renderMap = FragmentManager.renderFragments({
//         container,
//         fragments,
//         fragmentsDetails: fragments,
//         renderFragment: (fragmentContainer, fragment) => {
//           fragment.render(fragmentContainer, history, {
//             config,
//             eventBus,
//           });
//         },
//       });
//     }
//     return () => {
//       if (container) {
//         FragmentManager.unmountFragments({ container, renderMap });
//       }
//     };
//   }, []);
//   return (
//     <div ref={domRef} className="user-profile-fragment" style={{ width: '100%', height: '100%' }}>
//       <Spinner />
//     </div>
//   );
// };

export const handleSearchInput = (searchInput) => {
  var value = searchInput.value;
  var suggestionItems = [];

  if (value) {
    suggestionItems = searchInfo.searchList.filter((item) => {
      return item.text.toUpperCase().indexOf(value.toUpperCase()) === 0;
    });
  }

  // remove the previous suggestions
  [].slice.call(searchInput.children).forEach((child) => {
    searchInput.removeChild(child);
  });

  // add the new suggestions according to the  user input
  suggestionItems.forEach((item) => {
    var li = document.createElement('ui5-suggestion-item');
    li.text = item.text;
    searchInput.appendChild(li);
  });
};

export const handleNotificationClick = ({ event, notificationPopoverRef, profileMenuRef }) => {
  closeProfileMenu({ event, profileMenuRef });
  notificationPopoverRef.current.showAt(event.detail.targetRef);
};

export const handleProfileClick = ({ event, notificationPopoverRef, profileMenuRef }) => {
  notificationPopoverRef?.current?.close();
  profileMenuRef?.current?.showAt(event.detail.targetRef);
};

const openProfileSetting = ({ event, config, t, history, settings }) => {
  closeProfileMenu();
  eventBus.emit('dialog-show-hide', '', {
    type: 'user-profile',
    data: {
      showDialog: true,
      title: t('ProfileSettings'),
      content: () => {
        return <UserProfileBlock {...{ config, history, settings, eventBus }} />;
      },
    },
  });
};

const logout = () => {
  closeProfileMenu();

  eventBus.emit('dialog-show-hide', '', {
    type: 'user-logout',
    data: {
      showDialog: true,
      content: <LogoutForm eventBus={eventBus} />,
    },
  });
};

const closeProfileMenu = (event, profileMenuRef) => {
  profileMenuRef?.current?.close && profileMenuRef.current.close();
};

// const renderTopMenu = () => {};

export const handleSidenav = ({ showMenu, actions }) => {
  if (showMenu) {
    actions?.hideMenu?.call();
  } else {
    actions?.showMenu?.call();
  }
  eventBus.emit('sidenav-show-hide', null, { collapsed: showMenu });
  addConfig('SidenavCollapsed', showMenu);
};

export const Header = ({ user, t, config, common, actions, history, settings }) => {
  // const [logoutModalVisible, setLogoutModalVisible] = useState(false);
  const notificationPopoverRef = useRef();
  const profileMenuRef = useRef();
  const searchInputRef = useRef();
  let { notification, showMenu } = common;

  useEffect(() => {
    addConfig('SidenavCollapsed', !showMenu);
    actions?.startWebsocket && actions?.startWebsocket();
    return () => {
      // TODO actions.stopWebsocket
    };
  }, []);

  notification = notification.sort((item1, item2) => {
    const date1 = new Date(item1.receivedTime).getTime();
    const date2 = new Date(item2.receivedTime).getTime();
    if (item1.acknowledged && !item2.acknowledged) {
      return 1;
    }
    if (!item1.acknowledged && item2.acknowledged) {
      return -1;
    }
    return date2 - date1;
  });
  const filterdNotifications = notification.filter((item) => {
    return item.data && item.acknowledged === false;
  });

  return (
    <div>
      <Button
        style={{ position: 'absolute', zIndex: 1, color: '#ffffff', top: '4px' }}
        icon={showMenu ? 'menu' : 'menu2'}
        design={ButtonDesign.Transparent}
        onClick={(event) => handleSidenav({ showMenu, actions })}
      />
      <ShellBar
        className="shellbar"
        logo={<img alt="SAP Logo" src={logo} />}
        primaryTitle={t('AppName')}
        profile={getAvatar(user)}
        onProfileClick={(event) =>
          handleProfileClick({ event, notificationPopoverRef, profileMenuRef })
        }
        showNotifications
        notificationCount={filterdNotifications.length}
        onNotificationsClick={(event) =>
          handleNotificationClick({ event, notificationPopoverRef, profileMenuRef })
        }
        searchField={
          <Input
            ref={searchInputRef}
            onInput={(evt) => handleSearchInput(searchInputRef.current)}
            onSuggestionItemSelect={null}
            placeholder="Enter a term"
            showSuggestions={true}
            type="Text"
            value={undefined}
            valueState="None"
          />
        }
      />

      <Popover
        ref={notificationPopoverRef}
        allowTargetOverlap
        horizontalAlign="Right"
        placementType="Bottom"
        preventFocusRestore={false}
      >
        <div
          id="_eureka_claims_invoice_list_header_notification_menu"
          key="notifications1"
          className="sap-eureka-common__header__notification"
          style={{
            width: '410px',
            padding: '10px',
            maxHeight: '400px',
            overflow: 'scroll',
            overflowX: 'hidden',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            {((notification && notification.slice(0, 66)) || []).map((notification, index) => {
              const acknowledged = notification.acknowledged;
              const style = {
                width: '100%',
                display: 'inline-block',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              };
              if (acknowledged) {
                Object.assign(style, {
                  color: '#bbbbbb',
                });
              }
              return (
                <CustomNotification
                  key={'notification_' + notification.id}
                  notification={notification}
                  onCloseFunction={(evt) => actions.dismissNotification(notification)}
                  settings={settings}
                />
              );
            })}
          </div>
        </div>
      </Popover>

      <Popover
        ref={profileMenuRef}
        allowTargetOverlap
        horizontalAlign="Right"
        placementType="Bottom"
        preventFocusRestore={false}
      >
        <div>
          {/* <StandardListItem
            data-key="1"
            onClick={(event) =>
              openProfileSetting({ event, config, t, history, settings, eventBus })
            }
            icon={'user-settings'}
            infoState="None"
            selected={false}
            type="Active"
          >
            {t('ProfileSettings')}
          </StandardListItem> */}
          <StandardListItem
            data-key="2"
            onClick={logout}
            infoState="None"
            icon={'log'}
            selected={false}
            type="Active"
          >
            {t('Logout')}
          </StandardListItem>
        </div>
      </Popover>
    </div>
  );
};

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    common: state.common,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Header));
