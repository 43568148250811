import { COMMON_GET_NOTIFICATIONS_SUCCESS, COMMON_GET_NOTIFICATIONS_FAILED } from './constants';

import HeartBeatWebSocket from './HeartBeatWebSocket';
import eureka from 'eureka';
import eurekaMgrs from '@eureka/ui-managers';
const { eventBus } = eurekaMgrs;
const { axiosCreate } = eureka.HttpWrapper;
const axiosWrapper = axiosCreate({
  baseURL: `/api/notification-push/v1/messages`,
});
const axios = axiosWrapper.instance;
let __dispatch = null;
let __websocket = null;
let isMessageEnabled = false;

function onMessage(evt) {
  if (__dispatch) {
    fetchNotifications();
  }

  // evt = {
  //   tenantID: '1',
  //   employeeID: '1',
  //   data: {
  //     message: 'Deduction 867325330202624 has been auto filled',
  //     businessObject: 'Deduction',
  //     businessObjectID: '867325330202624',
  //     changeAction: 'AutoFill',
  //     changeResult: 'SUCCESS',
  //   },
  //   receivedTime: '2019-11-08T01:38:32.161Z',
  //   acknowledgedTime: '',
  //   acknowledged: ' false',
  // };

  console.log(evt);

  // refresh deduction table and status
  if (evt && evt.data) {
    if (
      evt.data.businessObject &&
      evt.data.businessObject === 'Deduction' &&
      evt.data.changeAction === 'AutoFill'
    ) {
      // setInterval(() => {
      //   eventBus.emit('DeductionAutoFilled', '', evt.data);
      // }, 20 * 1000);
      eventBus.emit('DeductionAutoFilled', '', evt.data);
    }
  }
}

export function refreshNewMessages() {
  return setInterval(() => {
    if (isMessageEnabled) {
      fetchNotifications();
    }
  }, 60 * 1000);
}

export function startWebsocket() {
  return (dispatch) => {
    if (__dispatch) {
      return;
    }
    __dispatch = dispatch;
    if (!__websocket) {
      let protocol = window.location.protocol.startsWith('https') ? 'wss' : 'ws';
      let url = `${protocol}://${window.location.host}/api/notification-push/`;
      __websocket = new HeartBeatWebSocket(url, onMessage).start();
    }
    fetchNotifications().then(
      (result) => {
        isMessageEnabled = true;
      },
      (error) => {
        isMessageEnabled = false;
        console.log('Message channel is supported, so will not start web socket');
      },
    );

    // listen close websocket of eventbus
    eventBus.on('close-websocket', (evtBody) => {
      console.log(evtBody);
      console.log('web socket close of eventbus');
      __websocket && __websocket._ws && __websocket._ws.close();
    });
  };
}

function fetchNotifications(args = {}, search = '', sortBy = '', pageSize = 10, pageCount = 1) {
  const promise = new Promise((resolve, reject) => {
    const doRequest = axios({
      url: '/',
      method: 'get',
      withCredentials: true,
    });
    doRequest.then(
      (res) => {
        __dispatch({
          type: COMMON_GET_NOTIFICATIONS_SUCCESS,
          data: (res && res.data) || [],
        });
        resolve(res);
      },
      (err) => {
        __dispatch({
          type: COMMON_GET_NOTIFICATIONS_FAILED,
          data: err,
        });
        reject(err);
      },
    );
  });
  return promise;
}

export function dismissNotification(notification) {
  return (disptach) => {
    return new Promise((resolve, reject) => {
      const doRequest = axios({
        url: `/${notification.id}`,
        method: 'POST',
        withCredentials: true,
      });
      doRequest.then(
        (res) => {
          fetchNotifications();
          resolve(res);
        },
        (err) => {
          fetchNotifications();
          reject(err);
        },
      );
    });
  };
}

export function dismissAllNotifications(notifications) {
  return (disptach) => {
    return new Promise((resolve, reject) => {
      const doRequest = axios({
        url: '/',
        method: 'post',
        withCredentials: true,
        body: notifications.map((notification) => {
          return Object.assign({}, notification, {
            acknowledged: true,
          });
        }),
      });
      doRequest.then(
        (res) => {
          fetchNotifications();
          resolve(res);
        },
        (err) => {
          fetchNotifications();
          reject(err);
        },
      );
    });
  };
}

export function deleteNotification(notification) {
  return (disptach) => {
    return new Promise((resolve, reject) => {
      const doRequest = axios({
        url: `/${notification.id}`,
        method: 'delete',
        withCredentials: true,
      });
      doRequest.then(
        (res) => {
          fetchNotifications();
          resolve(res);
        },
        (err) => {
          fetchNotifications();
          reject(err);
        },
      );
    });
  };
}

export function dismissAllNotification(notification) {
  return (disptach) => {
    return new Promise((resolve, reject) => {
      const doRequest = axios({
        url: `/${notification.id}`,
        method: 'post',
        withCredentials: true,
        data: Object.assign({}, notification, {
          acknowledged: true,
        }),
      });
      doRequest.then(
        (res) => {
          fetchNotifications();
          resolve(res);
        },
        (err) => {
          fetchNotifications();
          reject(err);
        },
      );
    });
  };
}

export function deleteAllNotification(notification) {
  return (disptach) => {
    return new Promise((resolve, reject) => {
      const doRequest = axios({
        url: `/`,
        method: 'delete',
        withCredentials: true,
        body: Object.assign({}, notification, {
          acknowledged: true,
        }),
      });
      doRequest.then(
        (res) => {
          fetchNotifications();
          resolve(res);
        },
        (err) => {
          fetchNotifications();
          reject(err);
        },
      );
    });
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case COMMON_GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notification: action.data,
      };
    case COMMON_GET_NOTIFICATIONS_FAILED:
      return {
        ...state,
        notification: [],
      };
    default:
      return state;
  }
}
