/* eslint-disable no-else-return */
/* eslint-disable import/order */
/* eslint-disable react/jsx-filename-extension */
import eurekaMgrs from '@eureka/ui-managers';
import '@ui5/webcomponents-icons/dist/activity-individual';
import '@ui5/webcomponents-icons/dist/documents';
import '@ui5/webcomponents-icons/dist/home';
import '@ui5/webcomponents-icons/dist/json-imports/Icons.js';
import '@ui5/webcomponents-icons/dist/kpi-corporate-performance';
import '@ui5/webcomponents-icons/dist/settings';
import '@ui5/webcomponents-icons/dist/visits';
// import { SideNavigation } from '@ui5/webcomponents-react/dist/SideNavigation';
import { SideNavigationItem } from '@ui5/webcomponents-react/dist/SideNavigationItem';
import { SideNavigationSubItem } from '@ui5/webcomponents-react/dist/SideNavigationSubItem';
// import { ListItemTypes } from '@ui5/webcomponents-react/dist/ListItemTypes';
import eureka from 'eureka';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getURLParam, removeFeatureFlaggedComponent } from '../../common/Utils';
import * as actions from './redux/actions';
// import SideNavigationWrapper from './SideNavigationWrapper';
import SideNavigationObserver from './SideNavigationObserver';

const { getConfig, getFeatureToggle } = eurekaMgrs.ConfigManager;
const { useTranslation } = eureka.I18nProvider;
const homeDisabled = true;

//react-jss style setup
const disabledColor = 'rgba(205, 206, 207, 0.8)';
const seletedColor = '#0854a0';
const enableColor = '#32363a';
const styles = {
  sideNavigation: {
    overflowY: 'auto',
    '& > div': {
      overflow: 'hidden',
    },
    '--_ui5_list_item_title_size': 'var(--sapFontSize)',
  },
  disabled: {
    color: disabledColor,
    backgroundColor: 'transparent !important',
    borderLeft: `4px solid transparent`,
    cursor: 'initial',
    '& ui5-icon': {
      color: disabledColor,
    },
    '& span': {
      color: disabledColor,
    },
  },
  enabled: {
    borderLeft: `4px solid transparent`,
    // backgroundColor: '#e5f0fa',
    '& ui5-icon': {
      color: enableColor,
    },
    '&[selected=true]': {
      borderLeft: `4px solid ${seletedColor}`,
      '& ui5-icon': {
        color: seletedColor,
      },
      '& span': {
        color: seletedColor,
      },
    },
  },
  customized: {
    '& >span:nth-child(3)': {
      position: 'absolute',
      right: 0,
      height: '48px',
      width: '240px',
      boxSizing: 'border-box',
      '& ui5-icon': {
        right: '13px',
        top: '16px',
        position: 'absolute',
      },
    },
  },
};
const useStyles = createUseStyles(styles);

function SidePanel(props) {
  const [selectedId, setSelectedId] = useState('home');
  const [appName, setAppName] = useState('');
  const { t } = useTranslation();
  const { showMenu } = props.common;
  // const { fetchUserPermissions } = props.actions;
  const classes = useStyles();
  const [currentUserPermissions, setCurrentUserPermissions] = useState([]);

  // FF for Approval-UI MFE
  const approvalsFeatureFlag = getFeatureToggle('ITCM-13-approvalList');

  useEffect(() => {
    const config = props.config;
    let pathname = props.history.location.pathname;
    const matchPatch = props.match.path;
    if (pathname !== matchPatch) {
      pathname = matchPatch;
    }

    if (pathname !== '/') {
      const cfg = getConfigValueByProp(config, 'router', pathname, 'id');
      if (cfg && cfg.id) {
        /* istanbul ignore next */
        setAppName(cfg.appName);
        /* istanbul ignore next */
        setSelectedId(cfg.id);
      } else {
        setAppName('');
        setSelectedId('home');
      }
    }
    return null;
  }, [props]);

  useEffect(() => {
    const permissions = getConfig('CurrentUserPermissions') || [];
    setCurrentUserPermissions(permissions);
    return null;
  }, []);

  const getConfigValueByProp = (config, propName, propValue, valueName) => {
    if (!config || !config.components) return;
    const sidenavComponents = config.components.filter((x) => !!x?.config.sidenav);
    for (let i = 0; i < sidenavComponents.length; i++) {
      const comp = sidenavComponents[i];
      for (let m = 0; m < comp.config.sidenav.length; m++) {
        const nav = comp.config.sidenav[m];
        if (nav[propName] && nav[propName] === propValue) {
          return {
            appName: comp.config.app,
            [valueName]: nav[valueName],
            disabled: isDisable(nav),
          };
        } else if (nav.items && Array.isArray(nav.items) && nav.items.length > 0) {
          for (let n = 0; n < nav.items.length; n++) {
            const item = nav.items[n];
            if (item[propName] && item[propName] === propValue) {
              return {
                appName: comp.config.app,
                [valueName]: item[valueName],
                disabled: isDisable(item),
              };
            }
          }
        } else if (
          comp.config.routers.includes(propValue) &&
          !['/kpi-library', '/next-best-actions-config'].includes(propValue)
        ) {
          return {
            appName: comp.config.app,
            [valueName]: nav[valueName],
            disabled: isDisable(nav),
          };
        }
      }
    }
  };

  /* istanbul ignore next */
  const onItemClick = (evt) => {
    const config = props.config;
    // const selectedId = evt.parameters.selectedId;
    const selectedId = evt.detail.item.id;

    if (!selectedId) {
      return;
    }
    const testingLngCode = getURLParam(props.history.location.search, 'sap-language');
    const testingLngParam = testingLngCode ? `?sap-language=${testingLngCode}` : '';

    if (selectedId === 'home' && !homeDisabled) {
      setSelectedId('home');
      props.history.push('/' + testingLngParam);
    } else {
      const cfg = getConfigValueByProp(config, 'id', selectedId, 'router');
      if (cfg && cfg.router && cfg.router !== '#' && !cfg.disabled) {
        try {
          props.history.push(cfg.router + testingLngParam);
        } catch (e) {
          console.log(e);
        }
      }
    }
  };

  /* istanbul ignore next */
  const renderNavItems = () => {
    const config = props.config;

    let listitems = [];
    if (config && config.components) {
      config.components.forEach((comp) => {
        const sidenav = comp.config.sidenav;
        if (sidenav && Array.isArray(sidenav) && sidenav.length > 0) {
          listitems = listitems.concat(sidenav);
        }
      });
    }

    listitems = listitems
      .filter((i) => i.items?.filter((c) => checkPermissions(c)).length > 0 || checkPermissions(i))
      .sort((a, b) => a.order - b.order);

    if (listitems) {
      // For MFE 'Approval-ui' if FF is disabled, don't display approvals in side-nav
      listitems = removeFeatureFlaggedComponent(listitems, 'approval', approvalsFeatureFlag);
    }

    return listitems.map((item, idx) => {
      // console.log(item.text, item.disabled);
      if (item.items && Array.isArray(item.items) && item.items.length > 0) {
        return (
          <SideNavigationItem
            expanded={!!item.items.find((child) => child.id === selectedId)}
            key={item.id}
            className={
              isDisable(item) ? classes.disabled : `${classes.enabled} ${classes.customized}`
            }
            onClick={null}
            text={t(item.text)}
            icon={item.icon}
            id={item.id}
            tooltip={item.text}
          >
            {item.items
              .filter((i) => checkPermissions(i))
              .map((child, childIdx) => (
                <SideNavigationSubItem
                  selected={child.id === selectedId}
                  key={child.id}
                  className={
                    isDisable(child) ? classes.disabled : `${classes.enabled} ${classes.customized}`
                  }
                  text={t(child.text)}
                  icon={child.icon}
                  id={child.id}
                  data-id={child.id}
                  tooltip={child.text}
                />
              ))}
          </SideNavigationItem>
        );
      } else {
        return (
          <SideNavigationItem
            key={item.id}
            selected={item.id === selectedId}
            className={
              isDisable(item) ? classes.disabled : `${classes.enabled} ${classes.customized}`
            }
            // style={{ backgroundColor: 'red !important' }}
            text={t(item.text)}
            icon={item.icon}
            id={item.id}
            tooltip={item.text}
          />
        );
      }
    });
  };

  const checkPermissions = (item) => {
    // eslint-disable-next-line no-prototype-builtins
    if (item.hasOwnProperty('skipPermission') && item.skipPermission) {
      return true;
    }

    if (item.permissions) {
      const permissions = Array.isArray(item.permissions)
        ? item.permissions.map((p) => p.toUpperCase())
        : [];
      if (item.conjunction && item.conjunction === 'OR') {
        return (
          permissions.length > 0 && permissions.some((p) => currentUserPermissions.includes(p))
        );
      }

      return permissions.length > 0 && permissions.every((p) => currentUserPermissions.includes(p));
    }
    return false;
  };

  // eslint-disable-next-line no-prototype-builtins
  const isDisable = (item) => item.hasOwnProperty('disabled') && item.disabled;

  return (
    <SideNavigationObserver
      className={classes.sideNavigation}
      collapsed={!showMenu}
      selectedId={selectedId}
      onSelectionChange={onItemClick}
      noIcons={false}
      style={{ height: '100%', fontSize: '14px' }}
      // footerItems={[
      //   <SideNavigationItem id="footer1" text="Legal Information" icon="compare" />,
      //   <SideNavigationItem id="footer2" text="Useful Links" icon="chain-link" />,
      // ]}
    >
      {renderNavItems()}
    </SideNavigationObserver>
  );
}

SidePanel.propTypes = {
  actions: PropTypes.object.isRequired,
  common: PropTypes.object.isRequired,
};
SidePanel.defaultProps = {};

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    common: state.common,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SidePanel);
